var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-form',{model:{value:(_vm.add_bank_account_component.is_form_valid),callback:function ($$v) {_vm.$set(_vm.add_bank_account_component, "is_form_valid", $$v)},expression:"add_bank_account_component.is_form_valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('label',[_vm._v(" Tipo de cuenta bancaria * ")]),_c('v-select',{attrs:{"color":"accent","items":_vm.add_bank_account_component.type_of_bank_account,"item-value":"value","item-text":"name","return-value":"","rules":_vm.add_bank_account_component.rules.required,"outlined":"","dense":"","hide-details":"","disabled":_vm.add_bank_account_component.add_other_bank_account_inputs.confirm_new_account},on:{"change":_vm.add_bank_account_component.loadForeignAccountCatalogs},model:{value:(_vm.add_bank_account_component.add_other_bank_account_inputs.type_of_bank_account),callback:function ($$v) {_vm.$set(_vm.add_bank_account_component.add_other_bank_account_inputs, "type_of_bank_account", $$v)},expression:"add_bank_account_component.add_other_bank_account_inputs.type_of_bank_account"}})],1)],1),(_vm.add_bank_account_component.is_national_account)?[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"8","sm":"8"}},[_c('label',[_vm._v(" Cuenta CLABE * ")]),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('#### #### #### #### ##'),expression:"'#### #### #### #### ##'"}],attrs:{"dense":"","color":"accent","placeholder":"0000 0000 0000 0000 00","outlined":"","rules":_vm.add_bank_account_component.rules.required,"disabled":_vm.add_bank_account_component.add_other_bank_account_inputs
            .confirm_new_account,"hide-details":""},model:{value:(_vm.add_bank_account_component.add_other_bank_account_inputs.bank_account),callback:function ($$v) {_vm.$set(_vm.add_bank_account_component.add_other_bank_account_inputs, "bank_account", $$v)},expression:"add_bank_account_component.add_other_bank_account_inputs.bank_account"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6","sm":"6"}},[_c('label',[_vm._v(" Banco * ")]),_c('v-autocomplete',{attrs:{"dense":"","outlined":"","hint":"Si no encuentra su banco, por favor contacte a su asesor Columbus.","persistent-hint":"","color":"accent","placeholder":"Buscar","autocomplete":"custom-autocomplete","items":_vm.add_bank_account_component.national_institution_paginated_entity.items,"item-text":_vm.add_bank_account_component.getSearchBankText,"return-object":"","search-input":_vm.add_bank_account_component.current_search_national_bank,"loading":_vm.add_bank_account_component.is_national_bank_searcher_loading,"disabled":_vm.add_bank_account_component.add_other_bank_account_inputs
            .confirm_new_account,"rules":_vm.add_bank_account_component.rules.required},on:{"update:searchInput":function($event){return _vm.$set(_vm.add_bank_account_component, "current_search_national_bank", $event)},"update:search-input":function($event){return _vm.$set(_vm.add_bank_account_component, "current_search_national_bank", $event)},"keyup":function($event){return _vm.add_bank_account_component.delaySearchBankInstitutions(true)},"change":function($event){return _vm.add_bank_account_component.setSelectedBankToSyncVariable(true)}},scopedSlots:_vm._u([{key:"append-item",fn:function(){return [_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:({
                  handler: _vm.add_bank_account_component.lazyLoadSearchNationalBankInstitutions,
                }),expression:"{\n                  handler: add_bank_account_component.lazyLoadSearchNationalBankInstitutions,\n                }"}]})]},proxy:true}],null,false,822172762),model:{value:(_vm.add_bank_account_component.selected_national_institution_entity),callback:function ($$v) {_vm.$set(_vm.add_bank_account_component, "selected_national_institution_entity", $$v)},expression:"add_bank_account_component.selected_national_institution_entity"}})],1)],1)]:[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"5","sm":"5"}},[_c('label',[_vm._v(" Divisa * ")]),_c('v-select',{attrs:{"color":"accent","items":_vm.add_bank_account_component.foreign_currencies,"item-text":"description","return-object":"","return-value":"","rules":_vm.add_bank_account_component.rules.currency,"outlined":"","dense":"","hide-details":"","disabled":_vm.add_bank_account_component.add_other_bank_account_inputs
            .confirm_new_account,"loading":_vm.add_bank_account_component.is_currencies_loading},on:{"change":_vm.add_bank_account_component.setSelectedCurrencyToSyncVariable},model:{value:(_vm.add_bank_account_component.selected_foreign_currency),callback:function ($$v) {_vm.$set(_vm.add_bank_account_component, "selected_foreign_currency", $$v)},expression:"add_bank_account_component.selected_foreign_currency"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"5","sm":"5"}},[_c('label',[_vm._v(" Banco * ")]),_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}]}),_c('v-autocomplete',{attrs:{"dense":"","outlined":"","hint":"Si no encuentra su banco, por favor contacte a su asesor Columbus.","persistent-hint":"","color":"accent","placeholder":"Buscar","autocomplete":"custom-autocomplete","items":_vm.add_bank_account_component.foreign_institution_paginated_entity.items,"item-text":_vm.add_bank_account_component.getSearchBankText,"return-object":"","search-input":_vm.add_bank_account_component.current_search_foreign_bank,"loading":_vm.add_bank_account_component.is_foreign_bank_searcher_loading,"disabled":_vm.add_bank_account_component.add_other_bank_account_inputs
            .confirm_new_account,"rules":_vm.add_bank_account_component.rules.required},on:{"update:searchInput":function($event){return _vm.$set(_vm.add_bank_account_component, "current_search_foreign_bank", $event)},"update:search-input":function($event){return _vm.$set(_vm.add_bank_account_component, "current_search_foreign_bank", $event)},"keyup":function($event){return _vm.add_bank_account_component.delaySearchBankInstitutions(false)},"change":function($event){return _vm.add_bank_account_component.setSelectedBankToSyncVariable(false)}},scopedSlots:_vm._u([{key:"append-item",fn:function(){return [_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:({
                  handler: _vm.add_bank_account_component.lazyLoadSearchForeignBankInstitutions,
                }),expression:"{\n                  handler: add_bank_account_component.lazyLoadSearchForeignBankInstitutions,\n                }"}]})]},proxy:true}]),model:{value:(_vm.add_bank_account_component.selected_foreign_institution_entity),callback:function ($$v) {_vm.$set(_vm.add_bank_account_component, "selected_foreign_institution_entity", $$v)},expression:"add_bank_account_component.selected_foreign_institution_entity"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"5","sm":"5"}},[_c('label',[_vm._v(" País * ")]),_c('v-select',{attrs:{"color":"accent","items":_vm.add_bank_account_component.countries,"item-text":"value","item-value":"id","return-value":"","rules":_vm.add_bank_account_component.rules.required,"outlined":"","dense":"","hide-details":"","disabled":_vm.add_bank_account_component.add_other_bank_account_inputs
            .confirm_new_account,"loading":_vm.add_bank_account_component.is_countries_loading},model:{value:(_vm.add_bank_account_component.add_other_bank_account_inputs
            .foreign_account.id_cat_pais),callback:function ($$v) {_vm.$set(_vm.add_bank_account_component.add_other_bank_account_inputs
            .foreign_account, "id_cat_pais", $$v)},expression:"add_bank_account_component.add_other_bank_account_inputs\n            .foreign_account.id_cat_pais"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"8","sm":"8"}},[_c('label',[_vm._v(" Cuenta"+_vm._s(_vm.add_bank_account_component.foreign_account_text)+" * ")]),_c('v-text-field',{attrs:{"dense":"","color":"accent","outlined":"","rules":_vm.add_bank_account_component.account_rule,"disabled":_vm.add_bank_account_component.add_other_bank_account_inputs
            .confirm_new_account,"hide-details":""},model:{value:(_vm.add_bank_account_component.add_other_bank_account_inputs
              .foreign_account.account_or_iban),callback:function ($$v) {_vm.$set(_vm.add_bank_account_component.add_other_bank_account_inputs
              .foreign_account, "account_or_iban", $$v)},expression:"add_bank_account_component.add_other_bank_account_inputs\n              .foreign_account.account_or_iban"}})],1)],1),(_vm.add_bank_account_component.is_foreign_account_eu)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"5","sm":"5"}},[_c('label',[_vm._v(" ABA * ")]),_c('v-text-field',{attrs:{"dense":"","color":"accent","outlined":"","rules":_vm.add_bank_account_component.rules.aba_number,"disabled":_vm.add_bank_account_component.add_other_bank_account_inputs
            .confirm_new_account,"hide-details":"","type":"number"},model:{value:(_vm.add_bank_account_component.add_other_bank_account_inputs
              .foreign_account.aba),callback:function ($$v) {_vm.$set(_vm.add_bank_account_component.add_other_bank_account_inputs
              .foreign_account, "aba", $$v)},expression:"add_bank_account_component.add_other_bank_account_inputs\n              .foreign_account.aba"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"5","sm":"5"}},[_c('label',[_vm._v(" SWIFT * ")]),_c('v-text-field',{attrs:{"dense":"","color":"accent","outlined":"","rules":_vm.add_bank_account_component.rules.foreign_bank_account,"disabled":_vm.add_bank_account_component.add_other_bank_account_inputs
            .confirm_new_account,"hide-details":""},model:{value:(_vm.add_bank_account_component.add_other_bank_account_inputs
              .foreign_account.swift),callback:function ($$v) {_vm.$set(_vm.add_bank_account_component.add_other_bank_account_inputs
              .foreign_account, "swift", $$v)},expression:"add_bank_account_component.add_other_bank_account_inputs\n              .foreign_account.swift"}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"8","sm":"8"}},[_c('label',[_vm._v(" For Further Credit To * ")]),_c('v-text-field',{attrs:{"dense":"","color":"accent","outlined":"","placeholder":"Nombre Apellido","rules":_vm.add_bank_account_component.rules.required,"disabled":_vm.add_bank_account_component.add_other_bank_account_inputs
            .confirm_new_account,"hide-details":""},model:{value:(_vm.add_bank_account_component.add_other_bank_account_inputs
              .foreign_account.for_further_credit_to),callback:function ($$v) {_vm.$set(_vm.add_bank_account_component.add_other_bank_account_inputs
              .foreign_account, "for_further_credit_to", $$v)},expression:"add_bank_account_component.add_other_bank_account_inputs\n              .foreign_account.for_further_credit_to"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"8","sm":"8"}},[_c('label',[_vm._v(" Beneficiario * ")]),_c('v-text-field',{attrs:{"dense":"","color":"accent","outlined":"","placeholder":"Beneficiario","rules":_vm.add_bank_account_component.rules.required,"disabled":_vm.add_bank_account_component.add_other_bank_account_inputs
            .confirm_new_account,"hide-details":""},model:{value:(_vm.add_bank_account_component.add_other_bank_account_inputs
              .foreign_account.beneficiary),callback:function ($$v) {_vm.$set(_vm.add_bank_account_component.add_other_bank_account_inputs
              .foreign_account, "beneficiary", $$v)},expression:"add_bank_account_component.add_other_bank_account_inputs\n              .foreign_account.beneficiary"}})],1)],1)],_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"onboarding--text"},[_vm._v(" Es necesario comprobar que la cuenta CLABE está a su nombre, para ello debe proporcionar un estado de cuenta no mayor a 3 meses, puede adjuntar una imagen o captura de pantalla dónde "),_c('b',[_vm._v("sean legibles la fecha, el nombre y cuenta CLABE")]),_vm._v(". ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","align":"center"}},[_c('div',[_c('preview-document',{directives:[{name:"show",rawName:"v-show",value:(_vm.add_bank_account_component.show_preview),expression:"add_bank_account_component.show_preview"}],attrs:{"document-name":"Estado de cuenta","file":_vm.add_bank_account_component.bank_statement_file}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.add_bank_account_component.show_preview),expression:"!add_bank_account_component.show_preview"}],attrs:{"src":require('@/assets/onboarding/state_account.svg'),"alt":"Ejemplo imagen estado de cuenta","height":"230"}}),_c('v-input',{attrs:{"rules":_vm.add_bank_account_component.rules.required},model:{value:(_vm.add_bank_account_component.bank_statement_file),callback:function ($$v) {_vm.$set(_vm.add_bank_account_component, "bank_statement_file", $$v)},expression:"add_bank_account_component.bank_statement_file"}})],1)]),_c('v-col',[_c('p',[_vm._v(" "+_vm._s(_vm.add_bank_account_component.bank_statement_file ? _vm.add_bank_account_component.bank_statement_file.name : '')+" ")]),_c('v-btn',{staticClass:"mb-5",attrs:{"outlined":"","width":_vm.$vuetify.breakpoint.smAndDown ? '100%' : 'auto',"color":"accent","loading":_vm.add_bank_account_component.is_file_loading,"disabled":_vm.add_bank_account_component.add_other_bank_account_inputs
                .confirm_new_account},on:{"click":function($event){return _vm.$refs.input_file.click()}}},[_vm._v(" Adjuntar Archivo ")]),_c('input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"input_file",attrs:{"type":"file","accept":_vm.add_bank_account_component.inputs_config.bank_statement.accept},on:{"change":_vm.add_bank_account_component.selectFile}}),_c('p',{staticClass:"onboarding--text"},[_vm._v(" NOTA: No es necesario el apartado de saldos y movimientos. ")]),_c('p',{staticClass:"onboarding--text"},[_vm._v(" Archivos Admitidos: JPG y PNG no mayor a 4 Mb. ")])],1)],1)],1)],1)],2),_c('v-checkbox',{attrs:{"color":"accent","label":"He constatado que toda mi información es correcta. *","rules":_vm.add_bank_account_component.rules.required,"disabled":_vm.add_bank_account_component.is_checkbox_disabled},model:{value:(_vm.add_bank_account_component.add_other_bank_account_inputs.confirm_new_account),callback:function ($$v) {_vm.$set(_vm.add_bank_account_component.add_other_bank_account_inputs, "confirm_new_account", $$v)},expression:"add_bank_account_component.add_other_bank_account_inputs.confirm_new_account"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }